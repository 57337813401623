export default function AboutSection() {
  return (
    <section id="main-container" className="main-container ">
      <div className="container">
        <div className="row text-center ">
          <div className="col-12 d-flex">
            <h4>
              Learn about the legacy of Rawafid, our journey, our vision, and
              our commitment to excellence. We pride ourselves on delivering
              high-quality solutions tailored to your needs!
            </h4>
            <h3 className="section-sub-title"></h3>
          </div>
        </div>
      </div>
    </section>
  );
}
