export default function CommitmentSection() {
  const clients = [
    {
      image_url: "../assets/images/clients/client1.png",
    },
    {
      image_url: "../assets/images/clients/client2.png",
    },
    {
      image_url: "../assets/images/clients/client3.png",
    },
    {
      image_url: "../assets/images/clients/client4.png",
    },
  ];

  return (
    <section id="main-container" className="main-container section-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h3 className="column-title">Our Commitment</h3>
            <p style={{ fontSize: "18px", lineHeight: "1.9rem" }}>
              We are committed to excellence, drawing on our extensive
              experience driving global commerce and infrastructure development,
              and supporting our clients with our extensive expertise and robust
              international network. <br></br>
              <br></br>
              Dedicated to leveraging our offshore status and extensive
              experience to provide innovative solutions that meet the evolving
              needs of the industry.
            </p>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0 d-flex justify-content-center">
            <img
              src="../assets/images/services/service1.jpg"
              alt="Service"
              width={"100%"}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
