import Col from "react-bootstrap/esm/Col";

export default function ServicesPageSection({ display = 20 }: any) {
  const services = [
    {
      title: "Maritime Transport Services",
      desc: "Provision of maritime transport activities, showcasing Rawafid's capabilities in navigating the complexities of international shipping and logistics.",
      icon: "../assets/images/icon-image/service-icon4.png",
    },
    {
      title: "Financial Investments and Loans",
      desc: "Acquisition of bonds, shares, and participations in non-resident foreign companies, along with providing loans to non-resident companies where Offshore owns a significant stake, more than 20% of the capital.",
      icon: "../assets/images/icon-image/service-icon5.png",
    },
    {
      title: "Licensing and Representation",
      desc: "Acquiring and exploiting rights for licenses of materials and merchandise, and acting as agents or representatives of foreign companies in international markets.",
      icon: "../assets/images/icon-image/service-icon6.png",
    },
    {
      title: "International Contract Negotiation and Execution",
      desc: "Rawafid excels in negotiating and signing contracts for operations and dealings executed abroad, specializing in transactions involving products and goods located abroad or within the Lebanese duty-free zone.",
      icon: "../assets/images/icon-image/service-icon1.png",
    },
    {
      title: "Global Company Management",
      desc: "Expertise in managing companies outside Lebanon, with activities exclusively abroad, including the export of professional services and all types of software, as well as providing administrative and organizational services to non-resident companies upon request.",
      icon: "../assets/images/icon-image/service-icon2.png",
    },
    {
      title: "Triangular and Multilateral Trade Operations",
      desc: "Facilitation of triangular or multilateral trade operations outside Lebanon, so that Offshore companies can negotiate, sign contracts, ship goods, and re-issue invoices for activities from and into Lebanon's customs-free zones, utilizing these zones to store merchandise to re-export purposes.",
      icon: "../assets/images/icon-image/service-icon3.png",
    },

    {
      title: "Global Expansion",
      desc: "Creation of branches and representative offices abroad, demonstrating Rawafid's commitment to global presence and international collaboration.",
      icon: "../assets/images/icon-image/service-icon1.png",
    },
    {
      title: "Economic Project Development",
      desc: "Building, investing, managing, and owning diverse economic projects, reflecting Rawafid's versatility and investment acumen.",
      icon: "../assets/images/icon-image/service-icon2.png",
    },
    {
      title: "Financial Operations and Loan Acquisition",
      desc: "Opening letters of credit and obtaining loans to finance operations and activities, leveraging relationships with banks and financial institutions both in Lebanon and internationally.",
      icon: "../assets/images/icon-image/service-icon3.png",
    },
    {
      title: "Real Estate Management",
      desc: "Rental of office spaces and acquisition of real estate as necessary for the Offshore company's activities, in compliance with Lebanese laws governing foreign real estate acquisition.",
      icon: "../assets/images/icon-image/service-icon4.png",
    },

    {
      title: "Thermal Steam Electric Station Rehabilitation",
      desc: "Enhancing facility efficiency, reliability, and sustainability through equipment modernization, infrastructure upgrades, and performance optimization.",
      icon: "../assets/images/icon-image/service-icon5.png",
    },

    {
      title: "Feasibility Studies & Engineering Assessments",
      desc: "Evaluating the condition and viability of thermal steam electrical stations through our approach that considers technical feasibility, economic viability, and environmental impact. ",
      icon: "../assets/images/icon-image/service-icon6.png",
    },
    {
      title: "Infrastructure Rehabilitation & Maintenance",
      desc: "Ensuring long-term operability and safety from structural repairs and corrosion mitigation to insulation upgrades and asset management.",
      icon: "../assets/images/icon-image/service-icon1.png",
    },
    {
      title: "Performance Monitoring & Optimization",
      desc: "Ensuring the ongoing efficiency and reliability of rehabilitated thermal steam electrical stations.",
      icon: "../assets/images/icon-image/service-icon2.png",
    },
    {
      title: "Project Planning & Management",
      desc: "Skilled project managers oversee all aspects: mitigating risks, optimizing resources, and ensuring adherence to timelines, budgets, and quality standards.",
      icon: "../assets/images/icon-image/service-icon3.png",
    },
  ];

  return (
    <section id="ts-service-area" className="ts-service-area pb-0">
      <div className="container">
        {display != 20 && (
          <>
            <div className="row text-center d-flex justify-content-center">
              <div className="col-8">
                <p style={{ fontSize: "22px", lineHeight: "1.9rem" }}>
                  Explore our range of services designed to meet the demands of
                  international trade, infrastructure development, and strategic
                  investment.
                  {/* <br></br>
                  <br></br>
                  Our expertise spans multiple sectors, ensuring that we deliver
                  innovative, efficient, optimal performance and sustainable
                  solutions. */}
                </p>

                <h3 className="section-sub-title"></h3>
              </div>
            </div>
          </>
        )}

        <div className="row d-flex justify-content-center">
          {services.slice(0, display).map((item, index) => (
            <Col key={index} lg={4}>
              <div className="ts-service-box">
                <div className="ts-service-box-info flex box-serv">
                  <img
                    className="mb-3"
                    loading="lazy"
                    src={item.icon}
                    alt="service-icon"
                  />
                  <h3 className="service-box-title">{item.title}</h3>
                  <p>{item.desc}</p>
                </div>
              </div>
            </Col>
          ))}
          {display != 3 && (
            <div className=" text-center  mb-4">
              <p style={{ fontSize: "22px" }}>
                Ready to thrive with us?{" "}
                <a style={{ color: "#5279b7" }} href="/contact">
                  Contact us today!
                </a>
              </p>
            </div>
          )}
          {display != 20 && (
            <div className="general-btn text-center mt-4 mb-4">
              <a className="btn btn-primary" href="services">
                All Services
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
