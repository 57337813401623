import FactsSection from "../components/HomeComponents/FactsSectionComponent";
import WelcomeSection from "../components/HomeComponents/WelcomeSectionComponent";
import { Helmet } from "react-helmet-async";
import ValuesSection from "../components/ServicesComponents/ValuesComponent";
import CommitmentSection from "../components/HomeComponents/CommitmentComponent";
import TeamSection from "../components/HomeComponents/TeamComponent";
import ServicesPageSection from "../components/ServicesComponents/ServicesComponent";
import ProjectsSection from "../components/HomeComponents/ProjectsComponent";

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Rawafid</title>
      </Helmet>
      <WelcomeSection />
      <ServicesPageSection display={3} />
      {/* <OurServicesSection /> */}
      {/* <FactsSection /> */}
      <CommitmentSection />
      <ValuesSection />
      {/* <RecentProjects /> */}
      <TeamSection />
      <ProjectsSection />
      {/* <ProjectsSection /> */}
      {/* <AboutSection /> */}
    </>
  );
}

export default HomePage;
