export default function ProjectsSection() {
  return (
    <section id="main-container" className="main-container ">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-6">
            <h3 style={{ marginBottom: "5px" }}>Projects</h3>
            <h5 className="column-title">Showcasing Our Global Impact</h5>
            <p style={{ fontSize: "18px", lineHeight: "1.9rem" }}>
              Our projects are a testament to Rawafid's capabilities and
              contribution to economic growth and sustainable development.
              <br></br>
              <br></br>
              From the rehabilitation of thermal and steam stations to the
              execution of large-scale maritime transport operations, each
              project underscores our commitment to quality and efficiency.
              <br></br>
              <br></br>
              We take pride in our portfolio which spans various industries and
              reflects our adaptability to the dynamic needs of the global
              market.
              <br></br>
              <br></br>
              Explore our projects to see the tangible results of our diverse
              expertise and strategic approach.
            </p>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0 d-flex justify-content-center">
            <img
              src="../assets/images/services/service7.jpg"
              alt="Service"
              width={"100%"}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
