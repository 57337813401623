import { Helmet } from "react-helmet-async";
import WelcomeSection from "../components/WelcomeComponent";
import ReachUsSection from "../components/ContactComponents/ReachUsComponent";

function ContactPage() {
  return (
    <>
      <Helmet>
        <title>Rawafid</title>
      </Helmet>
      <WelcomeSection name={"Contact Us"} />
      <ReachUsSection />
    </>
  );
}

export default ContactPage;
