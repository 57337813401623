import { Helmet } from "react-helmet-async";
import ServicesPageSection from "../components/ServicesComponents/ServicesComponent";
import WelcomeSection from "../components/WelcomeComponent";

function ServicesPage() {
  return (
    <>
      <Helmet>
        <title>Rawafid</title>
      </Helmet>
      <WelcomeSection name="Services" />
      <ServicesPageSection />
    </>
  );
}

export default ServicesPage;
