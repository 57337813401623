export default function DetailsSection() {
  return (
    <section id="main-container" className="main-container">
      <div className="container">
        {/* <div className="row text-center">
          <div className="col-12">
            <h4>
              Learn about the legacy of Rawafid, our journey, our vision, and
              our commitment to excellence. We pride ourselves on delivering
              high-quality solutions tailored to your needs!
            </h4>
            <h3 className="section-sub-title"></h3>
          </div>
        </div> */}

        <h3 className="column-title">Who We Are</h3>
        <div className="row d-flex justify-content-center ">
          <div className="col-lg-6">
            {/* <h3 className="column-title">Who We Are</h3> */}
            <p style={{ lineHeight: "1.9rem" }}>
              <b>Our Mission</b> Deliver exceptional service and cutting-edge
              technology in the rehabilitation of energy infrastructure.
              <br></br> We are dedicated to meeting the needs of today while
              being mindful of the environmental impact, ensuring that we leave
              a positive legacy for future generations.
            </p>
          </div>
          <div className="col-lg-6">
            <p style={{ lineHeight: "1.9rem" }}>
              <b>Our Vision</b> We envision a world powered by sustainable
              energy solutions.
              <br></br> Our commitment to this vision drives us to explore
              innovative methods to rehabilitate thermal power stations,
              reducing carbon footprints, and paving the way for a cleaner, more
              sustainable future.
            </p>
            {/* Page slider end*/}
          </div>
          {/* Col end */}
        </div>
        {/* Content row end */}
      </div>
      {/* Container end */}
    </section>
  );
}
