import React from "react";
import { Box } from "@mui/material";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Outlet } from "react-router-dom";
import FooterSection from "../../components/FooterComponent";

function ResponsiveDrawer() {
  return (
    <>
      <>
        <div id="top-bar" className="top-bar"></div>
        <header id="header" className="header-two">
          <div className="site-navigation">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <div className="logo">
                      <a className="d-block" href="./">
                        <img
                          loading="lazy"
                          src="./assets/images/logo.png"
                          alt="Constra"
                        />
                      </a>
                    </div>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target=".navbar-collapse"
                      aria-controls="navbar-collapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon" />
                    </button>
                    <div
                      id="navbar-collapse"
                      className="collapse navbar-collapse"
                    >
                      <ul className="nav navbar-nav ml-auto align-items-center">
                        <li className="nav-item">
                          <a className="nav-link" href="./">
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="services">
                            Services
                          </a>
                        </li>
                        {/* <li className="nav-item">
                          <a className="nav-link" href="projects">
                            Projects
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <a className="nav-link" href="about">
                            About Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="contact">
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>

      <Box>
        <Outlet />
        <FooterSection />
      </Box>
    </>
  );
}

export default ResponsiveDrawer;
