export default function JourneySection() {
  return (
    <section
      // style={{ padding: "20px" }}
      id="main-container"
      className="main-container section-bg"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h3 className="column-title">Our Journey & Legacy</h3>
            <p style={{ fontSize: "18px", lineHeight: "1.9rem" }}>
              Rawafid's establishment as an offshore entity has been a journey
              of strategic expansion and diversification. Our legacy is built on
              our ability to deliver complex projects across various sectors,
              including energy, maritime, financial, and real estate.
              <br></br>
              <br></br>
              <b>Our legacy </b>is built on our ability to deliver complex
              projects, including international commerce and infrastructure
              development with precision and innovation.
              <br></br>
              <br></br>
              <b> Our journey </b>has been marked by a series of strategic
              expansions and innovative solutions that have solidified our
              position as industry leaders.
            </p>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0">
            <img
              src="../assets/images/services/service6.jpg"
              alt="Service"
              width={"100%"}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
