import ResponsiveDrawer from "../sections/layouts/drawer";
import ScrollToTopButton from "../utils/ScrollToTopButton";
import { HelmetProvider } from "react-helmet-async";

function NavbarContainer() {
  return (
    <HelmetProvider>
      <ScrollToTopButton />
      <ResponsiveDrawer />
    </HelmetProvider>
  );
}

export default NavbarContainer;
