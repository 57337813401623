import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function WelcomeSection() {
  return (
    <div className="banner-carousel banner-carousel-2 mb-0">
      <div
        className="banner-carousel-item"
        style={{
          backgroundImage: "url(../assets/images/slider-main/bg4.jpg)",
        }}
      >
        <Container>
          <div className="box-slider-content">
            <div className="box-slider-text">
              {/* <h2 className="box-slide-title">17 Years Of Excellence In</h2> */}
              <h3 className="box-slide-sub-title">
                Powering the Future Via Innovating Solutions
              </h3>
              <p className="box-slide-description">
                Empowering Global Progress through Diverse Expertise in Energy,
                Maritime, Financial, and Real Estate Ventures
              </p>
              {/* <p>
                <a href="services.html" className="slider btn btn-primary">
                  Our Service
                </a>
              </p> */}
            </div>
          </div>
        </Container>
      </div>
      <div
        className="banner-carousel-item"
        style={{ backgroundImage: "url(../assets/images/slider-main/bg2.jpg)" }}
      >
        <div className="slider-content text-left">
          <Container>
            <div className="box-slider-content">
              <div className="box-slider-text">
                {/* <h2 className="box-slide-title">When Services Matters</h2> */}
                <h3 className="box-slide-sub-title">Your Choice is Simple</h3>
                <p className="box-slide-description">
                  Rawafid is a dynamic offshore company with a global vision,
                  excelling in a wide variety of services from international
                  trade, financial investments, and real estate management, to
                  infrastructure development.
                </p>
                {/* <p>
                  <a
                    href="about.html"
                    className="slider btn btn-primary"
                    aria-label="about-us"
                  >
                    Know Us
                  </a>
                </p> */}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
